@font-face {
  font-family: 'material';
  src:  url('fonts/material.eot?czixvz');
  src:  url('fonts/material.eot?czixvz#iefix') format('embedded-opentype'),
    url('fonts/material.ttf?czixvz') format('truetype'),
    url('fonts/material.woff?czixvz') format('woff'),
    url('fonts/material.svg?czixvz#material') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="mi-"], [class*=" mi-"] {
  font-family: 'material' !important;
  font-size: 1.2rem;
}

.mi-pin:before {
  content: "\e901";
}
.mi-pin-fill:before {
  content: "\e902";
}
